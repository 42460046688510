import React, { useContext } from 'react';
import { useRouter } from 'next/router';
import { LocaleContext } from '@/contexts/locale-context';
import { AdminContext } from '@/contexts/admin-context';
import { ChangesContext } from '@/contexts/changes-context';
import { MerchantContext } from '@/contexts/merchant-context';

export default function AdminInfoPanel() {
  const { translate } = useContext(LocaleContext);
  const {
    adminModeMerchantId,
    setAdminModeMerchantId,
    isAdminReviewMode,
    adminNotificationEnabled,
  } = useContext(AdminContext);
  const { isNavBarV2 } = useContext(MerchantContext);
  const {
    discardChanges, publishingState, changeStatus, sharedData, setPublishingState,
  } = useContext(ChangesContext);
  const router = useRouter();

  const onEditModeExit = () => {
    setAdminModeMerchantId(null);
    discardChanges();
    setPublishingState(null);
    router.push('/merchants/overview');
  };

  const onKeyDown = (event) => {
    if (event.key === 'Enter') {
      onEditModeExit();
    }
  };

  const getNotificationClassname = () => {
    let className = '';
    if (adminNotificationEnabled) {
      switch (publishingState) {
        case changeStatus.PUBLISHED:
          className = 'success';
          break;
        default:
          break;
      }
    }
    return className;
  };

  const getNotificationMessage = () => {
    let message = '';
    if (adminNotificationEnabled) {
      switch (publishingState) {
        case changeStatus.PUBLISHED:
          message = `${translate('merchants.infoPanelSuccess')} ${sharedData?.selectedMerchant}`;
          break;
        default:
          break;
      }
    }
    return message;
  };

  if (!isAdminReviewMode && !getNotificationMessage()) {
    return null;
  }

  return (
    <>
      <div id="info-panel-wrapper" className={getNotificationClassname()}>
        {isAdminReviewMode && (
          <>
            <div className="info-panel-text">
              {`${translate('merchants.infoPanelText')} ${adminModeMerchantId}`}
            </div>
            <button
              id="info-panel-button"
              type="button"
              onClick={onEditModeExit}
              onKeyDown={onKeyDown}
            >
              {translate('merchants.exitButton')}
            </button>
          </>
        )}
        <div>
          {getNotificationMessage()}
        </div>
      </div>
      <style jsx>
        {`
          #info-panel-wrapper {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 50px;
            width: ${isNavBarV2() ? 'calc(100vw - 280px)' : '100%'};
            padding: 0 50px;
            background-color: var(--color-notification-red);
            font-size: var(--font-info-panel);
            color: var(--color-white);
            text-align: center;
            position: fixed;
            top: 70px;
            z-index: 1;
          }

          #info-panel-wrapper.success {
            background-color: var(--color-green);
          }

          button {
            cursor: pointer;
            color: var(--color-white);
            background-color: transparent;
            border-radius: 21px;
            border: 1px solid var(--color-white);
            font-size: var(--font-size-body);
            font-weight: var(--font-weight-body);
            font-family: inherit;
            padding: 6px 22px;
            position: absolute;
            right: 50px;
          }

          button:hover {
            opacity: 0.7;
            transition: opacity 0.1s linear;
          }

          @media (min-width: 768px) {
            .info-panel-text {
              overflow-x: hidden;
              text-overflow: ellipsis;
              max-width: 60%;
            }
          }

          @media (max-width: 768px) {
            #info-panel-wrapper {
              flex-direction: column;
              justify-content: space-around;
              height: 90px;
              padding: 10px;
              top: ${isNavBarV2() ? '125px' : '130px'};
              row-gap: 5px;
              ${isNavBarV2() && 'width: 100vw;'}
            }
            button {
              position: static;
            }
          }
        `}
      </style>
    </>
  );
}
